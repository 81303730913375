<template>
  <v-container class="caption-outer">
    <v-row>
      <v-col />
      <v-col>
        <v-card max-width="400">
          <v-img :src="require('../assets/huellas.png')" contain />
          <div class="caption">
            <p
              class="name text-h4 text-sm-h3"
              :style="`font-family: ${fontFamily} !important;`"
            >
              <span :style="`color: ${fontColor};`">{{ firstname }}<br></span><br>
              <span :style="`color: ${fontColor};`">{{ lastname }}</span>
            </p>
          </div>
          <div class="caption-birthdate text-h6 text-sm-h5">
            <p>{{birthdate}}</p>
          </div>
          <div class="caption-info text-h6 text-sm-h5">
            <p>{{weight}}kgs - {{height}}cm - {{time}}</p>
          </div>
          <div class="caption-city text-h6 text-sm-h5">
            <p>{{city}}</p>
          </div>
        </v-card>
        <v-col class="justify-center">
          <h3>1. Escribe el Nombre</h3>
          <v-text-field v-model="firstname" label="Nombre"></v-text-field>
          <v-text-field v-model="lastname" label="Nombre"></v-text-field>
          <h3>2. Informacion</h3>
          <v-text-field v-model="birthdate" label="Fecha de Nacimiento"></v-text-field>
          <v-text-field v-model="weight" label="Peso"></v-text-field>
          <v-text-field v-model="height" label="Altura"></v-text-field>
          <v-text-field v-model="time" label="Hora"></v-text-field>
          <v-text-field v-model="city" label="Ciudad"></v-text-field>
          <h3>3. Tipo de letra</h3>
          <v-select :items="fonts" v-model="fontFamily"></v-select>
          <h3 style="padding-bottom: 30px">4. Elige un color</h3>
          <v-row class="justify-center"> 
            <v-btn @click="onPinkSelect()" color="#ffc0cb" />
            &nbsp;&nbsp;&nbsp;
            <v-btn @click="onBlueSelect()" color="#add8e6" />
          </v-row>
        </v-col>
      </v-col>
      <v-col />
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "Gorrito",
  data: () => ({
    firstname: "Nombre",
    lastname: "Apellido",
    birthdate: "01 Enero 2022",
    weight: "3.510",
    height: "60",
    time: "12:00 am",
    city: "Monterrey, NL",
    fonts: ["Grayscale", "Edwardian", "Chancery", "Bauhaus", "Garamond"],
    fontFamily: "Grayscale",
    fontColor: "#ffc0cb",
    pinkColor: "#ffc0cb",
    blueColor: "#add8e6",
  }),
  methods: {
    onPinkSelect() {
      this.fontColor = this.pinkColor;
    },
    onBlueSelect() {
      this.fontColor = this.blueColor;
    },
  },
};
</script>

<style scoped>
.name {
  font-weight: bold;
}

p {
  font-family: 'Courier New', Courier, monospace;
}

.caption-outer {
  position: relative;
}
.caption {
  position: absolute;
  top: 25%;
  left: 47%;
  transform: translate( -50%, -50% );
}
.caption-birthdate {
  position: absolute;
  top: 85%;
  left: 47%;
  transform: translate( -50%, -50% );
}
.caption-info {
  position: absolute;
  top: 90%;
  left: 47%;
  transform: translate( -50%, -50% );
  width: 20em;
}
.caption-city {
  position: absolute;
  top: 95%;
  left: 47%;
  transform: translate( -50%, -50% );
}
</style>