<template>
  <v-container class="caption-outer">
    <v-row>
      <v-col />
      <v-col>
        <v-card  max-height="400" max-width="400">
          <v-img :src="require('../assets/ribbon.jpg')" contain />
        </v-card>
        <div :style="`background-color:${blueColor}`">
          &nbsp;
          <p
            class="ribbon-text"
            :style="`color: ${fontColor}; font-family: ${fontFamily};`"
          >
            {{ firstname }}
          </p>
          &nbsp;
        </div>
        <v-col class="justify-center">
          <h3>1. Escribe el Nombre</h3>
          <v-text-field v-model="firstname" label="Nombre"></v-text-field>
          <h3>2. Tipo de letra</h3>
          <v-select :items="fonts" v-model="fontFamily"></v-select>
          <h3 style="padding-bottom: 30px">3. Elige un color</h3>
          <v-btn @click="onWhiteSelect()" :color="whiteColor" />
          &nbsp;&nbsp;
          <v-btn @click="onRoseSelect()" :color="roseColor" />
          &nbsp;&nbsp;
          <v-btn @click="onGraySelect()" :color="grayColor" />
          &nbsp;&nbsp;
          <v-btn @click="onBlueSelect()" :color="blueColor" />
        </v-col>
      </v-col>
      <v-col />
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "Gorrito",
  data: () => ({
    firstname: "Nombre",
    fonts: ["Grayscale", "Edwardian", "Chancery", "Bauhaus", "Garamond"],
    fontFamily: "Grayscale",
    fontColor: "#ffc0cb",
    whiteColor: "#ffffff",
    roseColor: "#f5efef",
    grayColor: "#bfbfbf",
    blueColor: "#081c67",
  }),
  methods: {
    onWhiteSelect() {
      this.fontColor = this.whiteColor;
    },
    onBlueSelect() {
      this.fontColor = this.blueColor;
    },
    onRoseSelect() {
      this.fontColor = this.roseColor;
    },
    onGraySelect() {
      this.fontColor = this.grayColor;
    },
  },
};
</script>

<style scoped>
.ribbon-text {
  font-size: 6.2vw;
  font-weight: bold;
}

.caption-outer {
  position: relative;
}
.caption {
  position: absolute;
  top: 73%;
  left: 45%;
  transform: translate(-50%, -50%);
}
</style>