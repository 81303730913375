<template>
  <v-app>
    <v-carousel
      height="auto"
      hide-delimiters
    >
      <v-carousel-item eager>
        <Babero/>
      </v-carousel-item>
      <v-carousel-item eager>
        <Gorrito/>
      </v-carousel-item>
      <v-carousel-item eager>
        <Mameluco/>
      </v-carousel-item>
      <v-carousel-item eager>
        <Repetidor/>
      </v-carousel-item>
      <v-carousel-item eager>
        <Sabana/>
      </v-carousel-item>
      <v-carousel-item eager>
        <Oso/>
      </v-carousel-item>
      <v-carousel-item eager>
        <Cobija/>
      </v-carousel-item>
      <v-carousel-item eager>
        <Ribbon1/>
      </v-carousel-item>
      <v-carousel-item eager>
        <Ribbon2/>
      </v-carousel-item>
      <v-carousel-item eager>
        <Cuadro/>
      </v-carousel-item>
    </v-carousel>
  </v-app>
</template>

<script>
// @ is an alias to /src
import Babero from '@/components/Babero.vue'
import Gorrito from '@/components/Gorrito.vue'
import Mameluco from '@/components/Mameluco.vue'
import Repetidor from '@/components/Repetidor.vue'
import Sabana from '@/components/Sabana.vue'
import Oso from '@/components/Oso.vue'
import Cobija from '@/components/Cobija.vue'
import Ribbon1 from '@/components/Ribbon1.vue'
import Ribbon2 from '@/components/Ribbon2.vue'
import Cuadro from '@/components/Cuadro.vue'


export default {
  name: 'Home',
  components: {
    Babero,
    Gorrito,
    Mameluco,
    Repetidor,
    Sabana,
    Oso,
    Cobija,
    Ribbon1,
    Ribbon2,
    Cuadro,
  }
}
</script>
