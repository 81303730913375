import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/babero',
    name: 'Babero',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/BaberoView.vue')
  },
  {
    path: '/gorrito',
    name: 'Gorrito',
    component: () => import('../views/GorritoView.vue')
  },
  {
    path: '/mameluco',
    name: 'Mameluco',
    component: () => import('../views/MamelucoView.vue')
  },
  {
    path: '/repetidor',
    name: 'Repetidor',
    component: () => import('../views/RepetidorView.vue')
  },
  {
    path: '/sabana',
    name: 'Sabana',
    component: () => import('../views/SabanaView.vue')
  },
  {
    path: '/oso',
    name: 'Oso',
    component: () => import('../views/OsoView.vue')
  },
  {
    path: '/cobija',
    name: 'Cobija',
    component: () => import('../views/CobijaView.vue')
  },
  {
    path: '/ribbon1',
    name: 'Ribbon1',
    component: () => import('../views/Ribbon1View.vue')
  },
  {
    path: '/ribbon2',
    name: 'Ribbon2',
    component: () => import('../views/Ribbon2View.vue')
  },
  {
    path: '/cuadro',
    name: 'Cuadro',
    component: () => import('../views/CuadroView.vue')
  },
]

const router = new VueRouter({
  routes
})

export default router
