<template>
  <v-container class="caption-outer">
    <v-row>
      <v-col />
      <v-col>
        <v-card max-height="400" max-width="400">
          <v-img :src="require('../assets/gorrito.png')" contain />
          <div class="caption">
            <p
              class="img-text text-h3 text-sm-h2"
              :style="`color: ${fontColor}; font-family: ${fontFamily} !important;`"
            >
              {{ firstname }}
            </p>
          </div>
        </v-card>
        <v-col class="justify-center">
          <h3>1. Escribe el Nombre</h3>
          <v-text-field v-model="firstname" label="Nombre"></v-text-field>
          <h3>2. Tipo de letra</h3>
          <v-select :items="fonts" v-model="fontFamily"></v-select>
          <h3 style="padding-bottom: 30px">3. Elige un color</h3>
          <v-row class="justify-center"> 
            <v-btn @click="onPinkSelect()" color="#ffc0cb" />
            &nbsp;&nbsp;&nbsp;
            <v-btn @click="onBlueSelect()" color="#add8e6" />
          </v-row>
        </v-col>
      </v-col>
      <v-col />
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "Gorrito",
  data: () => ({
    firstname: "Nombre",
    fonts: ["Grayscale", "Edwardian", "Chancery", "Bauhaus", "Garamond"],
    fontFamily: "Grayscale",
    fontColor: "#ffc0cb",
    pinkColor: "#ffc0cb",
    blueColor: "#add8e6",
  }),
  methods: {
    onPinkSelect() {
      this.fontColor = this.pinkColor;
    },
    onBlueSelect() {
      this.fontColor = this.blueColor;
    },
  },
};
</script>

<style scoped>
.img-text {
  font-weight: bold;
}
.caption-outer {
  position: relative;
}
.caption {
  position: absolute;
  top: 72%;
  left: 45%;
  transform: translate( -50%, -50% );
}
</style>